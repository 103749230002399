<template>
  <v-scale-transition>
    <v-btn
      v-if="$vuetify.breakpoint.lgAndUp"
      :disabled="loading"
      :loading="loading"
      color="primary"
      @click.stop="$emit('click')"
    >
      <v-icon left>
        {{ icon }}
      </v-icon>
      {{ label }}
    </v-btn>
    <v-btn
      v-else
      :disabled="loading"
      :loading="loading"
      color="primary"
      bottom
      fab
      fixed
      right
      @click.stop="$emit('click')"
    >
      <v-icon>
        {{ icon }}
      </v-icon>
    </v-btn>
  </v-scale-transition>
</template>

<script>
export default {
  name: 'BasePrimaryActionButton',

  props: {
    icon: {
      type: String,
      default: 'mdi-plus',
    },

    label: {
      type: String,
      default: '',
    },

    loading: Boolean,
  },
};
</script>

<style scoped>

</style>
