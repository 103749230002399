<template>
  <td :colspan="colspan">
    <slot></slot>
    <v-row>
      <template v-for="header in headers">
        <v-col
          :key="header.value"
          cols="12"
          sm="6"
        >
          <span class="text-subtitle-2">
            {{ header.text }}
          </span>
          <br>
          <slot v-if="item[header.value]" :name="`item.${header.value}`">
            {{ item[header.value] }}
          </slot>
          <span v-else>
            -
          </span>
        </v-col>
      </template>
    </v-row>
    <slot name="append"></slot>
  </td>
</template>

<script>
export default {
  name: 'BaseExpandedTableRow',

  props: {
    headers: {
      type: Array,
      default: () => [],
    },

    colspan: {
      type: Number,
      default: 1,
    },

    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>

</style>
