<template>
  <v-row dense class="pa-4">
    <v-col :cols="showSearch ? 12 : 6" sm="6">
      <h1 class="text-h6 d-flex align-center">
      <span class="mr-1">
        {{ tableTitle }}
      </span>
        <slot
          v-if="selectedRows.length"
          name="mass-actions"
        />
      </h1>
    </v-col>
    <v-col :cols="showSearch ? 12 : 6" sm="6" class="d-flex justify-end">
      <v-text-field
        v-if="showSearch"
        :label="$t('search')"
        class="mr-md-4 mt-4 mt-sm-0 pt-0"
        prepend-inner-icon="mdi-magnify"
        clearable
        hide-details
        single-line
        @input="onSearchInput"
      />
      <BasePrimaryActionButton
        v-if="createButton"
        :icon="createButtonIcon"
        :label="createButton"
        :loading="showButtonLoader"
        @click="$emit('new-item')"
      />
      <slot name="append"/>
    </v-col>
  </v-row>
</template>

<script>
import BasePrimaryActionButton from './BasePrimaryActionButton';

export default {
  name: 'BaseTableHeader',

  components: { BasePrimaryActionButton },

  props: {
    title: {
      type: String,
      default: '',
    },

    createButton: {
      type: String,
      default: '',
    },

    createButtonIcon: {
      type: String,
      default: 'mdi-plus',
    },

    selectedRows: {
      type: Array,
      default: () => [],
    },

    showButtonLoader: Boolean,

    showSearch: Boolean,
  },

  data() {
    return {
      searchTimerID: null,
    };
  },

  computed: {
    tableTitle() {
      return this.selectedRows?.length
        ? `${this.$t('marked')}: ${this.selectedRows.length}`
        : this.title;
    },
  },

  methods: {
    onSearchInput(val) {
      clearTimeout(this.searchTimerID);
      this.searchTimerID = setTimeout(() => {
        this.$emit('update:search', { search: val });
      }, 500);
    },
  },
};
</script>

<style scoped>

</style>
